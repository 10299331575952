import http from '../../../utils/services/http'
export const list = (limit, page, filtros, isGerencia = false) => {
    let url = '/api/produtos?page=' + page + '&limit=' + limit + '&' + filtros
    if (isGerencia) {
        url = url + '&req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id, isGerencia = false) => {
    let url = `/api/produtos/${id}`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findContratoUso = (id, isGerencia = false) => {
    let url = `/api/produtos/${id}/contratoUso`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newProduto = (data) => {
    let url = `/api/produtos`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateProduto = (produto, method = 'PUT') => {
    let url = `/api/produtos/${produto.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, produto)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteProduto = (id) => {
    let url = `/api/produtos/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const contratarProduto = (id, data) => {
    let url = `/api/produtos/${id}/contratar?req-gerencia=1`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const ativarContrato = (id) => {
    let url = `/api/contratos/${id}/ativar?req-gerencia=1`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
